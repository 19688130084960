<!-- =========================================================================================
  File Name: PartnerEdit.vue
  Description: Partner Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/partner/pixinvent
========================================================================================== -->

<template>
  <div id="page-partner-edit">

    <vs-alert :active.sync="partner_not_found" color="danger" title="Partner Not Found">
      <span>Partner record with id: {{ $route.params.partnerId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link to="/a/partners" class="text-inherit underline">All Partners</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="partner_data">

      <div class="tabs-container px-6 pt-6" slot="no-body">

        <vs-tabs class="tab-action-btn-fill-conatiner" v-model="activeTab">
          <vs-tab icon="icon-partner" icon-pack="feather" label="General">
            <div class="tab-text">
              <partner-edit-tab-general :data="partner_data" class="mt-4"/>
            </div>
          </vs-tab>

        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import PartnerEditTabGeneral from './PartnerEditTabGeneral.vue';


// Store Module

export default {
  components: {
    PartnerEditTabGeneral,
  },
  data() {
    return {
      partner_data: null,
      partner_not_found: false,
      activeTab: 0,
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  watch: {
    activeTab() {
      this.fetchPartnerData(this.$route.params.partnerId);
    },
  },
  methods: {
    fetchPartnerData(partnerId) {
      this.$http.get(`partners/${partnerId}`)
        .then(response => {

          if (response.data.data) {
            const data = response.data.data;
            this.partner_data = data;
          }

        })
        .catch(error => {
          if (error.response.status === 404) {
            this.partner_not_found = true;
            return;
          }
          console.error(error);
        });
    },
  },
  created() {
    const partnerId = this.activeUserInfo.partner_id;

    this.fetchPartnerData(partnerId);
  },
};

</script>
